// Contact.js
import React from 'react';
import { Mail, Linkedin } from 'lucide-react';

const Contact = () => (
  <section id="contact" className="py-16 px-4 pb-[80px] g-black/50">
    <div className="max-w-4xl mx-auto text-center">
      <h2 className="text-4xl font-bold mb-12 text-orange-400">Get In Touch</h2>
      <div className="flex justify-center space-x-8">
        <a href="mailto:adamksmetana@gmail.com" className="text-blue-400 hover:text-blue-300">
          <Mail className="h-8 w-8" />
        </a>
        <a href="https://www.linkedin.com/in/adamsmetana" className="text-teal-400 hover:text-teal-300">
          <Linkedin className="h-8 w-8" />
        </a>
      </div>
    </div>
  </section>
);

export default Contact;