// ProjectDetail.js
import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { projects } from './projects';
import { slugify } from './utils';

const ProjectDetail = () => {
  const { title } = useParams();
  const project = projects.find(p => slugify(p.title) === title);

  useEffect(() => {
    window.scrollTo(0, 0);  // Scrolls to the top-left corner of the page
  }, []); 

  if (!project) {
    return <div className="text-center text-white py-16">Project not found</div>;
  }

  return (
    <div className="min-h-screen bg-gray-900 py-16 px-4 project-detail-item">
      <div className="max-w-4xl mx-auto pt-[50px] pb-[50px]">
        <div class="mb-[30px]">
          <Link 
          to="/#projects" 
          className="bg-white text-teal-500 border-2 border-teal-500 px-5 py-3 rounded-lg font-bold hover:bg-teal-500 hover:text-white transition-colors duration-300"
        >
          ← Back to Projects
          </Link>
        </div>

        
        <div className="bg-gray-800 rounded-lg p-8">
          <img 
            src={project.image_md} 
            alt={project.title} 
            className="w-full h-64 object-cover rounded-lg mb-8" 
          />
          
          <h1 className="text-4xl font-bold text-teal-400 mb-4">
            {project.title}
          </h1>
          
          <p className="text-gray-300 mb-6">
            {project.description}
          </p>
          
          <div className="mb-6">
            <h2 className="text-2xl font-bold text-teal-400 mb-3">
              Technologies Used
            </h2>
            <div className="flex flex-wrap gap-2">
              {project.technologies.map((tech, index) => (
                <span 
                  key={index} 
                  className="bg-teal-400/20 text-teal-400 px-3 py-1 rounded-full"
                >
                  {tech}
                </span>
              ))}
            </div>
          </div>
          
          <div className="mb-6">
            <h2 className="text-2xl font-bold text-teal-400 mb-3">
              Project Details
            </h2>
            <p className="text-gray-300">
              {project.details}
            </p>
          </div>
          
          {project.link && (
            <a 
              href={project.link} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="inline-block bg-teal-400 text-gray-900 px-6 py-2 rounded-lg hover:bg-teal-300 transition-colors"
            >
              Visit Project
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;