import React, { useState, useEffect } from 'react';

const Home = () => {
  const [displayName, setDisplayName] = useState('');
  const [displayDescription, setDisplayDescription] = useState('');
  
  const fullName = "Adam Smetana";
  const fullDescription = "I'm a developer with an eye for design and a desire to make the best web experiences possible.";
  
  // Typing animation
  useEffect(() => {
    let nameIndex = 0;
    const nameInterval = setInterval(() => {
      if (nameIndex <= fullName.length) {
        setDisplayName(fullName.slice(0, nameIndex));
        nameIndex++;
      } else {
        clearInterval(nameInterval);
        let descIndex = 0;
        const descInterval = setInterval(() => {
          if (descIndex <= fullDescription.length) {
            setDisplayDescription(fullDescription.slice(0, descIndex));
            descIndex++;
          } else {
            clearInterval(descInterval);
          }
        }, 50);
      }
    }, 150);

    return () => clearInterval(nameInterval);
  }, []);

  return (
    <div className="bg-black">
      {/* About Section */}
      <section id="about" className="pt-32 pb-12 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4 bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 text-transparent bg-clip-text min-h-[4rem]">
            {displayName}
            <span className="inline-block w-[2px] h-8 bg-blue-400 ml-1 animate-pulse">|</span>
          </h1>
          <p className="subheading text-2xl text-yellow-400 font-black font-bold lowercase">Not just another Front End Engineer</p>
          <p className="mt-6 text-lg text-gray-300 min-h-[3rem] font-bold">
            {displayDescription}
            <span className="inline-block w-[2px] h-5 ml-1 animate-pulse">|</span>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Home;