// Projects.js
import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from './utils';

import * as Utils from './utils';
console.log('Utils:', Utils);  // Check what's being imported

const projects = [
  { id: 1, title: "MeTV", image: "/images/sm/metv_sm.png", description: "MeTV project image for portfolio",
    technologies: ["HTML", "JavaScript", "CSS", "PHP"],
    // link: "/images/project_full/metv_portfolio.jpg",
    link: "https://metv.com",
    details: "", image_md:"/images/md/metv.png"},
  { id: 1.2, title: "Catchy Comedy", image: "/images/sm/catchy_sm.png", description: "Catchy Comedy project image for portfolio",
      technologies: ["HTML", "JavaScript", "CSS"],
      //link: "/images/project_full/sm/catchy_portfolio.jpg",
      link: "https://catchycomedy.com",
      image_md:"/images/md/catchy.png", 
      details: "" },
  { id: 1.5, title: "Ruth Page Dance Company", image: "/images/sm/ruthpage_sm.png", description: "Ruth Page project image for portfolio",
        technologies: ["Webflow", "JavaScript", "CSS"],
        //link: "/images/project_full/ruthpage_portfolio.jpg",
        link: "https://ruthpage.org/home",
        image_md:"/images/md/ruth_page.png",
        details: "" },
  { id: 2, title: "Yikesgeist", image: "/images/sm/yikesgeist_sm.png", technologies: ["HTML", "JavaScript", "CSS", "PHP"],
    //link: "/images/project_full/yikesgeist_portfolio.jpg", 
    link: "https://metv.com/yikesgeist", description: "Yikesgeist project image for portfolio",
    image_md: "/images/md/yikesgeist.png",
    details: ""},
  { id: 2.5, title: "Regina Jones", image: "/images/sm/regina_sm.png", technologies: ["HTML", "JavaScript", "CSS", "Figma"],
      //link: "/images/project_full/regina_portfolio.jpg", 
      link: "https://whoisreginafilm.com",  description: "Regina project image for portfolio",
      image_md: "/images/md/regina.png",
      details: ""  },
  { id: 4, title: "MeTV Toons", image: "/images/sm/toons_sm.png", description: "MeTV Toons project image for portfolio",
    technologies: ["HTML", "JavaScript", "CSS"],
    //link: "/images/project_full/toons_portfolio.jpg",
    link: "https://metvtoons.com", 
    image_md:"/images/md/toons.png",
    details: "" },
  { id: 6, title: "Svengoolie", image: "/images/sm/sven_sm.png", description: "Svengoolie project image for portfolio",
    technologies: ["HTML", "JavaScript", "CSS", "PHP"],
    //link: "/images/project_full/sven_portfolio.jpg", 
    link: "https://svengoolie.com",
    image_md: "/images/md/sven.png",
    details: "" },
  { id: 7, title: "CBS58", image: "/images/sm/cbs58_sm.png", description: "CBS58 project image for portfolio",
    technologies: ["HTML", "JavaScript", "CSS"],
    //link: "/images/project_full/cbs58_portfolio.jpg",
    link: "https://cbs58.com", 
    image_md: "/images/md/cbs58.png",
    details: "" },
  { id: 8, title: "Ensono", image: "/images/sm/ensono_sm.png", description: "Ensono project image for portfolio",
    technologies: ["Drupal", "Bootstrap", "HTML","JavaScript", "CSS"],
    //link: "/images/project_full/ensono_portfolio.jpg",
    linke: "https://ensono.com", 
    image_md: "/images/md/ensono.png",
    details: "" },
/*   { id: 9, title: "OTA", image: "/images/ota_sm.png", description: "MeTV project description...",
      technologies: ["React", "JavaScript", "CSS"],
      link: "/images/project_full/ota_portfolio.jpg",
      details: "Detailed information about MeTV project..." }, */
/*   { id: 10, title: "S&F", image: "/images/ota_sm.png", description: "MeTV project description...",
technologies: ["React", "JavaScript", "CSS"],
link: "/images/project_full/ota_portfolio.jpg",
details: "Detailed information about MeTV project..." }, */
/*   { id: 11, title: "Crush Medical Debt", image: "/images/ota_sm.png", description: "MeTV project description...",
technologies: ["React", "JavaScript", "CSS"],
link: "/images/project_full/ota_portfolio.jpg",
details: "Detailed information about MeTV project..." }, */
  // Add other projects here
];

const Projects = () => (
  <section id="projects" className="py-16 px-4 bg-black/50">
    <div className="max-w-6xl mx-auto">
      <h2 className="text-4xl font-bold text-center mb-12 text-emerald-400">My Work</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {projects.map((project) => (
          <Link 
            to={`/project/${slugify(project.title)}`} 
            key={project.id} 
            className="project-item bg-gray-900 rounded-lg overflow-hidden hover:transform hover:scale-105 transition-transform duration-300 border border-gray-800 hover:text-white"
          >
            <img src={project.image} alt={project.title} className="w-full h-48 object-cover" />
            <h3 className="text-teal-400 text-xl font-bold p-4 text-center hover:text-white">{project.title}</h3>
          </Link>
        ))}
      </div>
    </div>
  </section>
);

export default Projects;

export { projects };