import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    // Instead of this:
    // background: ${props => props.theme.background};
    
    // Do this to ensure the property exists:
    background: ${props => props.theme?.background || '#ffffff'};  // Provide a fallback
  }
`;

export default GlobalStyle;