import React from 'react';

const Footer = ({ className }) => {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <footer className={`bg-gray-800 text-white text-center pb-[17px] pt-[19px] synthwave-container flex items-center justify-center text-center ${className}`}>
      <p>&copy; {currentYear}</p>
    </footer>
  );
};

export default Footer;
