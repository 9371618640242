import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const navLightColor = 'rgb(67 0 90 / 75%)';

  const navLight = {
    backgroundColor: isScrolled ? 'rgb(0, 0, 0)' : navLightColor,
    transition: 'background-color 3s ease-in-out'
  };

  const navItems = [
    { title: 'About', href: '#about' },
    { title: 'Projects', href: '#projects' },
    { title: 'Skills', href: '#skills' },
    { title: 'Contact', href: '#contact' }
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav 
      style={navLight} 
      className={`fixed w-full z-50 transition-all duration-500 ease-in-out ${
        isScrolled ? 'scroll backdrop-blur-sm shadow-lg' : 'default'
      }`}
    >
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <span className="text-white font-bold">AS</span>
          <div className="hidden md:flex space-x-8">
            {navItems.map((item) => (
              <a key={item.title} href={item.href} className="text-gray-300 transition-colors nav-link font-bold">
                {item.title}
              </a>
            ))}
          </div>
          <button className="md:hidden text-white" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? <X /> : <Menu />}
          </button>
        </div>
        {isMenuOpen && (
          <div className="md:hidden absolute top-16 left-0 right-0 bg-black/95 backdrop-blur-lg border-t border-gray-800">
            {navItems.map((item) => (
              <a key={item.title} href={item.href} className="block px-4 py-3 text-gray-300 nav-link" onClick={() => setIsMenuOpen(false)}>
                {item.title}
              </a>
            ))}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;