import './App.css';
import './style.css';

import React, { useState } from 'react';

import { ThemeProvider } from 'styled-components';
import GlobalStyle from './globalStyle';
import darkTheme from './themes/darkTheme';  // adjust path as needed
import lightTheme from './themes/lightTheme';  // adjust path as needed

import Navbar from './components/navbar';
import Home from './components/home';
import Projects from './components/projects';
import Skills from './components/skills';
import Contact from './components/contact';
/*import Synthwave from './components/synthwave';*/
import Footer from './components/footer';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ProjectDetail from './components/projectdetail';

function MainLayout() {
  return (
    <>
      <Home />
      <Projects />
      <Skills />
      <Contact />
    </>
  );
}

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<MainLayout />} />
          <Route path="/project/:title" element={<ProjectDetail />} />
        </Routes>
        <FooterWithConditionalMargin />
      </div>
    </Router>
  );

  const [isDarkMode, setIsDarkMode] = useState(false);
  
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <GlobalStyle />
      {/* Your other components */}
    </ThemeProvider>
  );
}

// Define a component to conditionally apply a class to the Footer based on the route
function FooterWithConditionalMargin() {
  const location = useLocation();

  // Set condition for the route where you want a different footer margin (e.g., "/project/:id")
  const isProjectDetailPage = location.pathname.startsWith('/project/');

  return <Footer className={isProjectDetailPage ? 'footer no-mt-footer' : 'footer'} />;
}

export default App;
