// Skills.js
import React from 'react';

import {
  Layout,
  Database,
  Server,
  Smartphone
} from 'lucide-react';

const skills = [
  { name: "Coding Languages", icon: <Layout className="h-8 w-8 text-cyan-400" />, description: "HTML, CSS, Javascript, PHP", color: "text-cyan-400" },
  { name: "Frameworks and Libraries", icon: <Server className="h-8 w-8 text-purple-400" />, description: "React, Python, SCSS/Stylus, Tailwind CSS, Material UI, Bootstrap", color: "text-purple-400" },
  { name: "Collaboration", icon: <Database className="h-8 w-8 text-green-400" />, description: "Git, Command Line, Visual Studio, Sublime, Figma, Adobe Photoshop", color: "text-green-400" },
  { name: "Web Performance and Optimization", icon: <Smartphone className="h-8 w-8 text-rose-400" />, description: "Google Analytics, Google Search Console, SEO, Web Accessibility", color: "text-rose-400" }
];

const Skills = () => (
   <section id="skills" className="py-16 px-4">
   <div className="max-w-6xl mx-auto">
     <h2 className="text-4xl font-bold text-center mb-12 text-pink-400">Skills</h2>
     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
       {skills.map((skill, index) => (
         <div 
           key={index} 
           className="group bg-gray-900 rounded-lg p-6 text-center border border-gray-800 
                     transition-all duration-300 hover:bg-gray-800 hover:transform 
                     hover:-translate-y-2 hover:shadow-lg hover:shadow-cyan-500/20 cursor-pointer"
         >
           <div className="flex justify-center mb-4 transform group-hover:scale-110 transition-transform duration-300">
             {skill.icon}
           </div>
           <h3 className={`text-xl font-semibold mb-2 ${skill.color} group-hover:scale-105 transition-transform duration-300`}>
             {skill.name}
           </h3>
           <p className="text-gray-300 transition-all duration-300 group-hover:text-white">
             {skill.description}
           </p>
         </div>
       ))}
     </div>
   </div>
 </section>
);

export default Skills;
